/* Reset some default browser styles */

body {
  font-family: 'Arial', sans-serif;
  background: url(../../assets/MAP.png) no-repeat center center fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.police-logo {
  position: absolute;
  top: 30px;
  left: 30px;
  height: 100px;
}

.header {
  width: 600px;
  height: 400px;
}

.login-container {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 12%;
}

.left-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}

.logo {
  width: 250px;
  height: 250px;
  margin-bottom: 10px;
  padding: -10px;
  top: 100px;
  left: 240px;
  position: relative;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.right-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-box {
  background-color: white;
  padding: 13px;
  border-radius: 34px;

  text-align: center;
  overflow: visible;
}

.signin-title {
  margin-bottom: 20px;
  font-size: 40px;
  color: #333;
  position: relative;
  top: -70px;
}

.input-field {
  width: 100%;
  padding: 15px;
  margin-bottom: 30px;
 
}

.input-field {
  width: 90%;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  position: relative;

  height: 38px;
  text-align: center;
}

.signin-button {
  width: 95%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007BFF;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative;
  top: -10px;
  

}

.signin-button:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .login-container {
    flex-direction: row;
    padding: 15px;
    height: 499px;
  }

  .logo {
    width: 200px;
    height: 200px;
    top: 140px;
    left: 100px;
  }


  .signin-title {
    font-size: 35px;
  }

  .input-field {
    padding: 15px;
  }

  .signin-button {
    padding: 8px;
  }
}

@media (max-width: 992px) {
  .login-container {
    flex-direction: column;
  }

  .logo {
    width: 180px;
    height: 180px;
  }

  .login-box {
    width: 90%;
    margin-top: 20px;
  }

  .signin-title {
    font-size: 30px;
    top: -40px;
  }

  .input-field {
    padding: 15px;
  }

  .signin-button {
    padding: 8px;
  }
}

@media (max-width: 770px) {
  .police-logo {
    height: 80px;
    display: block;
    margin: 0 auto; /* Center the logo horizontally */
  }

  .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .logo {
    width: 100px; /* Adjust size for smaller screens */
    height: 100px;
    margin: 20px 0; /* Center the logo vertically with margin */
  }

  .login-box {
    width: 100%;
    max-width: 400px; /* Limit the width for better appearance on small screens */
    padding: 15px;
    box-sizing: border-box; /* Ensure padding doesn't affect width */
  }

  .signin-title {
    font-size: 25px;
    margin-bottom: 20px; /* Add space below the title */
    text-align: center; /* Center the title text */
  }

  .input-field {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px; /* Add space below each input field */
    box-sizing: border-box; /* Ensure padding doesn't affect width */
  }

  .signin-button {
    width: 100%;
    padding: 10px;
    font-size: 18px;
    border-radius: 5px;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    text-align: center; /* Center the button text */
  }

  .signin-button:hover {
    background-color: #0056b3;
  }
}


@media (max-width: 900px) {
  .police-logo {
    height: 82px;
  }

  .login-container {
    flex-direction: column;
    padding: 8px;
    width: 400px;
  }

  .logo {
    width: 120px;
    height: 120px;
    top: 50px;
    left: 240px;
  }

  .login-box {
    width: 100%;
    padding: 6px;
  }

  .signin-title {
    font-size: 20px;
    top: -20px;
  }

  .input-field {
    padding: 10px;
  }

  .signin-button {
    padding: 6px;
  }
}
 @media (max-width: 440px) {
    body {
        font-family: 'Arial', sans-serif;
        background: url(../../assets/MAP.png) no-repeat center center fixed;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 0px;
        width: 0px;
      }
  .police-logo {
    height: 120px !important;
  }

  .login-container {
    flex-direction: column;
    padding: 8px;
    width: 365px;
    position: absolute;
    top: 200px;
    height: 424px;
    left: 30px;
  }

  .logo {
    width: 120px;
    height: 120px;
    top: 50px;
    left: 237px;
  }

  .login-box {
    width: 100%;
    padding: 62px;
    height: auto;
  }

  .signin-title {
    font-size: 20px;
    top: -20px;
  }

  .input-field {
    padding: 10px;
  }

  .signin-button {
    padding: 6px;
  }
}
@media (max-width: 430px) {
    body {
        font-family: 'Arial', sans-serif;
        background: url(../../assets/MAP.png) no-repeat center center fixed;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 0px;
        width: -10px;
      }
  .police-logo {
    height: 120px !important;
  }

  .login-container {
    flex-direction: column;
    padding: 8px;
    width: 365px;
    position: absolute;
    top: 200px;
    height: 424px;
    left: 66px;
  }

  .logo {
    width: 120px;
    height: 120px;
    top: 50px;
    left: 237px;
  }

  .login-box {
    width: 100%;
    padding: 62px;
    height: auto;
  }

  .signin-title {
    font-size: 20px;
    top: -20px;
  }

  .input-field {
    padding: 10px;
  }

  .signin-button {
    padding: 6px;
  }
}
@media (max-width: 400px) {
    body {
        font-family: 'Arial', sans-serif;
        background: url(../../assets/MAP.png) no-repeat center center fixed;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 0px;
        width: -10px;
      }
  .police-logo {
    height: 150px !important;
    left: 82px;
  }

  .login-container {
    flex-direction: column;
    padding: 8px;
    width: 365px;
    position: absolute;
    top: 249px;
    height: 424px;
    left: 115px;
  }

  .logo {
    width: 120px;
    height: 120px;
    top: 50px;
    left: 237px;
  }

  .login-box {
    width: 100%;
    padding: 62px;
    height: auto;
  }

  .signin-title {
    font-size: 20px;
    top: -20px;
  }

  .input-field {
    padding: 10px;
  }

  .signin-button {
    padding: 6px;
  }
}

@media (max-width: 450px) {
    body {
        font-family: 'Arial', sans-serif;
        background: url(../../assets/MAP.png) no-repeat center center fixed;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 0px;
        width: -10px;
      }
  .police-logo {
    height: 150px !important;
    left: 146px;
  }

  .login-container {
    flex-direction: column;
    padding: 8px;
    width: 365px;
    position: absolute;
    top: 249px;
    height: 424px;
    left: 65px;
  }

  .logo {
    width: 120px;
    height: 120px;
    top: 50px;
    left: 237px;
  }

  .login-box {
  

    height: auto;
  }

  .signin-title {
    font-size: 20px;
    top: -20px;
  }

  .input-field {
    padding: 10px;
  }

  .signin-button {
    padding: 6px;
  }

}