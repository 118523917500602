/* Main navigation container */
.nav {
  display: flex;
  align-items: center; /* Align items horizontally */
  gap: 20px; /* Adjust the gap between nav items */
  font-family: "Montserrat", sans-serif;
  font-size: small;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  box-sizing: border-box;
  /* backdrop-filter: blur(1px); */
  width: 100%;
  padding: 10px;
  background-color: rgba(255, 255, 255); 
}

.h1 {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  flex-direction: column; /* Stack the 'new' label above the text */
  align-items: center; /* Center the text and the 'new' label */
  justify-content: center;
  margin: 0 15px;
  cursor: pointer;
  position: relative;
  height: 40px; /* Fix a height to ensure it doesn't shift when 'new' is added */
  overflow: hidden; /* Prevent any overflow of content */
}

/* Active link effect - Fix the positioning of the 'new' label */
.h1.active .new-feature {
  text-decoration: none;
  position: absolute;
  top: -4px; /* Slightly adjust the positioning */
  left: 50%;
  transform: translateX(-50%); /* Center the 'new' label horizontally */
  z-index: 10; /* Ensure it's above the text */
}

/* Ensure the 'new' label is visible by default */
.new-feature {
  color: rgb(0, 255, 0);
  font-size: 0.8rem;
  text-align: center;
  display: block;
  margin-top: 0; /* Remove any extra margin */
  position: absolute; /* Position the label outside the text flow */
  top: -4px; /* Position it just above the text */
  left: 50%;
  transform: translateX(-50%); /* Center the label horizontally */
  z-index: 10; /* Ensure the label appears above text */
}

/* User icon container */
.user-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  transition: transform 0.3s ease;
  box-sizing: border-box;
}

.user-icon-container:hover {
  transform: scale(1.1); /* Slightly increase size on hover */
}

.user-icon {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image fits within the container */
}

/* Mobile responsive adjustments */
@media screen and (max-width: 768px) {
  .nav {
    flex-direction: column; /* Stack the items vertically on smaller screens */
    gap: 10px; /* Adjust spacing between items */
  }
  .h1 {
    font-size: 18px; /* Adjust font size for smaller screens */
    margin: 5px;
  }
}
/* Dropdown menu style */
.tracking-dropdown {
  position: relative;
  display: inline-block;
  margin-top: 7px;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 150px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content .non-bold-link {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: black;
  font-weight: normal;
}

.dropdown-content .non-bold-link:hover {
  background-color: #f1f1f1;
}

/* Styling for the left box */
.leftbox {
  width: 850px;
  height: 620px;
  top: 143px;
  left: 44px;
  border-radius: 20px;
  border: 3px solid #b7e2e7;
  margin-left: 50px;
}

/* Styling for the right box */
.rightbox {
  position: absolute;
  width: 360px;
  height: 263px;
  top: 250px;
  left: 1000px;
}

/* Profile styling */
.profile {
  position: absolute;
  padding-left: 20px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.user {
  width: 108px;
  height: 108px;
  position: relative;
  left: 380px;
  top: 100px;
}

.profilerole {
  font-size: 30px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: bolder;
  line-height: 43px;
  position: relative;
  top: 135px;
  padding-left: 20px;
}

.h1.selected::after {
  content: "";
  /* bottom: -5px; 
  left: 0;
  right: 0;
  height: 5px; */
  background-image: url("../../assets/line.png"); /* Use the imported line image */
  background-size: cover;
  background-repeat: no-repeat;
}
