/* Default styles for all screen sizes */
.nav {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 2rem;
    font-family: 'Montserrat';
    font-size: 1.2rem;
    justify-content: space-around;
    margin-left: auto;
    padding: 10px 0;
}
.nav-button {
    background-color: #ADD8E6; /* Light blue color */
    border: solid black;
    cursor: pointer;
    font-family: 'Montserrat';
    font-size: 18px; /* Adjust font size as needed */
    margin-right: 20px; /* Adjust spacing between buttons */
    color:black; /* Text color */
    padding: 10px 20px; /* Adjust padding as needed */
}

.nav-button:hover {
    background-color: #87CEEB; /* Light blue color on hover */
    text-decoration: underline; /* Add underline on hover */
}


.selectstation {
    border: solid;
    
    width: 80%;
    margin: 0 auto; /* Center the element horizontally */
    background: #fff;
    display: flex;
    justify-content: space-evenly;
    border-radius: 8px;
    border-color: #B7E2E7;
    opacity: 0.8;
}


.select {
    width: 100px;
    height: 35px;
    border: solid;
    border-radius: 5px;
}

.task {
    border: solid;
    width: 90%;
    padding-left: 2rem;
    padding-right: 2rem;
    position: relative;
    left: 60px;
    background: #fff;
    border-color: #B7E2E7;
    opacity: 0.8;
    border-radius: 10px;
}

.names {
    display: flex;
    gap: 2rem;
    
}

.inputclass {
    height: 30px;
    flex: 1 1 45%; 
}
.background{
    width: 100%;
    height: 100%;
}

.fetched-user{
    background-color: white;
    width: 100px;
    margin-right: -400px;
}
.userlist{
    background-color: white;
    height: 74px;
    position: relative;
    /* margin-left: -366px;
    margin-right: -357px; */
    border: solid;
    border-radius: 17px;
}
@media screen and (min-width:200px)and(max-width:749px) {
    .userlist{
        position: relative;
        left:90px;   
    }
}

.select_station {
        width: 200%;
        /* margin-left: 54px; */
        position: relative;
        /* left: -532px; */
    }

    .staffmembers {
        display: flex;
        align-items: center;
        gap: 5px;
        position: relative;
        left: 30px;
      }
      
      .staffmembers h2 {
        display: flex;
        align-items: center;
        gap: 5px;
      }
      
      .nameareaofselectarea {
        background-color: grey;
        color: white;
        padding: 0 5px;
        border-radius: 4px;
      }
      

/* Media query for smaller screens */
@media only screen and (max-width: 768px) {
    .nav {
        width: 50%;
        margin: 0 auto; /* Center the element horizontally */
        font-size: 0.5rem;
        gap: 3rem; /* Decrease the gap between items */
        margin-left: 10px;
    }
    
    .selectstation {
        width: 90%; /* Adjust the width for smaller screens */
        font-size: 0.5rem;
    }
    .names{
        font-size: smaller;
    }
    .inputclass{
        width: 20%;
    }
    
    .task {
        left: 30px; /* Adjust the left position for smaller screens */
        padding: 1rem; /* Adjust the padding for smaller screens */
        margin-right:30vw
    }

}
@media only screen and (max-width: 600px) {
    .names {
        flex-direction: column;
        border: solid; /* Display items vertically */
    }

    .names > div {
        width: 100%; /* Set the width of each item to 100% */
        margin-bottom: 1rem; /* Add some space between each row */
    }
    .inputclass{
        margin: 0 auto;
        width: 50%;
        font-size: 30px;
        margin-bottom: 20px;
        border-width: 5px;
    }
    .task{
        width: 80%; /* Adjust width for smaller screens */
        margin: 0 auto;/* Center the task section */
        margin-left:20px ;
        margin-right: 20px;
        align-items: center;
        justify-content: center;
        
    }
    .names h3 {
        position: static;
        margin-bottom: 15px;
        font-size: large; /* Add space between the number and input fields */
        margin:0 auto;
        margin-top: 20px;
    }
    .nav {
        width: 50%;
        margin: 0 auto; /* Center the element horizontally */
        font-size: 0.5rem;
        gap: 1.5rem; /* Decrease the gap between items */
        margin-left: 6vw;
        width:50%;
        
    }
    .names label {
        font-size: large;
        font-weight: bolder;
        
    }
    

}
body {
    margin: 0; /* Reset margin */
    padding: 0; /* Reset padding */
    background-size: cover; /* Ensure background image covers the viewport */
    background-position: center; /* Center the background image */
}



@media only screen and (max-width: 560px) {
    
    .nav {
        width: 100%;
        font-size: 0.3rem;
        display: flex;
        margin-left: 2vw;
       
        gap: 1rem; /* Decrease the gap between items */
    }

    .selectstation {
        width: 90%; /* Adjust width for smaller screens */
        height: fit-content;
        margin: 0 auto; /* Center the select station */
    }

    .task {
        width: 80%; /* Adjust width for smaller screens */
        margin: 0 auto;/* Center the task section */
        margin-left:20px ;
        margin-right: 20px;
        align-items: center;
        justify-content: center;
       
    }
    .background{
        min-width: 170vw;
        min-height: 300vh;
    }

    .names {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center items */
    }

    .names h3 {
        margin-bottom: 10px; /* Add space below the number */
    }

    .inputclass {
        width: 30%; /* Adjust input width for smaller screens */
        margin-bottom: 10px; /* Add space below each input */
    }

    .names label {
        font-size: 0.9rem; /* Adjust label font size for smaller screens */
    }

    .names input {
        font-size: 0.9rem; /* Adjust input font size for smaller screens */
    }
    .logo {
        height:10vw;
        width: 13vw

    }
    .user {
        height:10vw;
        width: 13vw

    }
    .task h2 {
        font-size: small;
    }
}
@media only screen and (min-width: 600px) and (max-width: 768px){
    .task{
        width: 90%;
        
    }
    .task h2{
        align-items: center;
    }
    .background{
        min-width: 170vw;
        min-height: 300vh;
    }
    .userlist{
        
        height: 50px;
        
        margin-left: -36px;
        margin-right: -27px;
        
    }
    h2{
        font-size: 1rem;
    }
}
@media only screen and (min-width: 769px) and (max-width: 1200px){
    .task{
        width:80%;
    }
    .inputclass{
        width: 50%
    }
    .nav {
        font-size: small;
    }
    
}
@media only screen and (min-width: 1200px){
    .task{
        width:85%
    }
    .userlist{
        
        height: 74px;
        
        margin-left: -256px;
        margin-right: -247px;
        
    }
}
@media only screen and (max-width:500px){
    .background{
        min-width: 210vw;
        min-height: 300vh;
    }
    h2{
        font-size: 0.8rem;
    }
}
@media only screen and (min-width: 560px) and (max-width: 600px){
    .background{
        min-width: 210vw;
        min-height: 300vh;
    }
    .nav{
        width: 90%;
    }
    h2{
        font-size: 1rem;
    }
}
@media only screen and (min-width: 500px) and (max-width: 600px){
    h2{
        font-size: 0.8rem;
    }
    .userlist{
        
        height: 40px;
        /* width: 700px; */
        margin-left: 6px;
        margin-right: 2px;
        /*  */
    }
}
@media only screen and (min-width: 768px) and (max-width: 940px){
    .background{
        min-width: 120vw;
        min-height: 300vh;
    }
    h2{
        font-size: 1.2rem;
    }
    .userlist{
        
        height: 55px;
        width: 700px;
        margin-left: -106px;
        margin-right: -97px;
        /*  */
    }

}
@media only screen and (min-width: 940px) and (max-width: 1200px){
    .userlist{
        
        height: 74px;
        
        margin-left: -206px;
        margin-right: -197px;
        
    }
}

