/*staffmanagement*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.staff {
  overflow-y: hidden;
  overflow-x: hidden;
}
.selectstation {
  border: solid;
  /* margin-left: 0;  */
  background: #fff;
  display: flex;
  justify-content: space-evenly;
  border-radius: 8px;
  border-color: #b7e2e7;
  opacity: 0.8;
  padding: 1rem; 
}

.select_station {
  margin-left: 140px;
  position: relative;
  left: -306px;
}

.select_station {
  border: solid;
  background: #fff;

  align-items: center;
  display: flex;
  justify-content: space-evenly;
  border-radius: 12px;
  border-color: #b7e2e7;
  opacity: 0.8;
  margin: 120px 0px 30px;
}
.select_copstation {
  border: solid;
  background: #fff;

  align-items: center;
  display: flex;
  justify-content: space-evenly;
  border-radius: 12px;
  border-color: #b7e2e7;
  opacity: 0.8;
  margin: 120px 0px 30px;
}
.selectoption {
  height: 35px;
  border: solid;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
}
.form {
  justify-content: con;
}
.tasks {
  border: solid;

  position: relative;
  background: #fff;
  opacity: 0.8;
  margin: 20px 20px;
  border-color: #b7e2e7;
  padding-left: 24px;
  border-radius: 10px;
}
.details {
  display: flex;
  gap: 20px;
}
.name {
  margin-top: 10px;
  margin-bottom: 10px;

  gap: 10px;
}
.detail {
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 10px;
}
.input_class {
  height: 25px;
  padding: 4px;
  margin-left: 10px;
}
.buttons {
  display: flex;
  gap: 15px;
  justify-content: center;
  width: 80%;
  margin-top: 10px; 
}
.buttonADD,
.buttonREMOVE {
  background-color: #009adc;
  height: 50px;
  border: none;
  border-radius: 10px;
  text-align: center;
  color: white;
  /* margin: 20px 10px; */
  margin: 10px 10px;
  font-weight: bold;
  width: 200px;
  cursor: pointer;
}

.buttonADD:hover,
.buttonREMOVE:hover {
  background-color: #007bbd;
  transition: background-color 0.3s ease;
}
.staffmanagement-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh; 
  padding-top: 30px;
}
.staffmember {
  background-color: white;
  height: 220px;
  opacity: 0.8;
  box-sizing: border-box;
  position: absolute;
  border: 3px solid #B7E2E7;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
}
h2{
  margin-top: 20px;
  font-weight: bolder;
  text-align: center;
  margin-bottom: 45px;
}

.inputbox {
  width: 66%;
  height: 30px;
  margin-left: 10px;
  margin-right: 10px;
  background: #FFFFFF;
  border: 3px solid #B7E2E7 !important;
  border-radius: 20px;
  padding: 50px;
  text-align: center;
}

.nameinput {
  display: flex;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
.form-group_name {
  margin-left: 20px;
  gap: 20px;
  width: 45%;
}
.form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
  width: 80%;
}
label {
  margin-bottom: 5px;
}
.form-group_phone {
  width: 45%;
}
.nameinput form {
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
}
.input::placeholder {
  text-align: center; 
}
p {
  margin-top: 5px;
  color: red;
  font-size: 12px;
  text-align: center;
}
@media screen and (min-width: 420px) {
  .select_station {
    height: 90px;
  }
  .select_copstation{
    position: relative;
    left: 16px;
    width: 98%;
  }
  .staffmember{
    position: relative;
    left: 20px;
    width: 95%;
  }
  .selectoption {
    /*: 100px; */
    height: 35px;
    border: solid;
    /* background-color: #fff; */
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
  }

  h3 {
    font-size: small;
  }
  .details {
    display: flex;
    gap: 10px;
  }
  .tasks {
    padding: 10px;
  }
  .input_class {
    height: 20px;
  }
}

@media (min-width: 300px) and (max-width:440px) {
  .form{
    display: contents;
  }
  .select_station {
    margin-left: auto;
    margin-right: auto;
  }
  .select_copstation{
    position: relative;
    left: 212px;
    width: 99%;
  }
 
  .selectoption {
    width: 20px;
  }
  .staffmember{
    position: relative;
    width: 418px;
    left: 210px;
  }
  

  /* .select_copstation {
    position: relative;
    left: 212px;
    width: 99%;
  } */
  .select_copstation {
    border: solid;
    background: #fff;
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    border-radius: 12px;
    border-color: #b7e2e7;
    opacity: 0.8;
    margin: 120px 0px 30px; 
    width: 100%;
    box-sizing: border-box; 
  }
  
  h3 {
    font-size: smaller;
  }

  .tasks {
    padding: 10px;
  }
  .input_class {
    height: 20px;
  }
}
@media screen and(max-width:441px)and (min-width:499px) {
  .staffmember{
    position: relative;
    left: 190px;
  }
  .select_copstation{
    position: relative;
    left: 160px;
    width: 98%;
  }
}
@media (min-width: 500px) {
  .select_copstation {
    position: relative;
    left: 10px;
    /* right: 20px; */
    width: 100%;
  }
  .select_copstation .form{
    display: contents;
  }
  h3 {
    font-size: medium;
  }

  .tasks {
    padding: 10px;
  }
  .input_class {
    height: 20px;
  }
}

@media (min-width: 768px)and(max-width:1246px) {
  .staffmember {
    margin-left: 0px;
    margin-right: -97px;
  }
  .select_copstation {
    position: relative;
    left: -30px;
    /* right: 20px; */
    width: 110%;
  }
}

@media (min-width: 992px) {
  .tasks {
    padding: 10px;
  }
  .select_copstation {
    position: relative;
    left: -115px;
    width: 110%;
  }
  .staffmember {
    margin-left: -106px;
    margin-right: -97px;
  }
  .input_class {
    height: 25px;
  }
}

@media (min: 1190px) {
  .staffmember {
    margin-left: -106px;
    margin-right: -97px;
  }
  .input_class {
    height: 25px;
    padding: 4px;
  }
  .select_copstation {
    position: relative;
    left: -40px;
    width: 110%;
  }
  .tasks {
    padding: 10px;
  }
}
