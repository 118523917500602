.hamburger {
  position: fixed;
  top: 80px; /* Adjusted this value to move it lower */
  left: 20px;
  width: 30px;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 1100; /* Above the sidebar */
}

.hamburger:focus {
  outline: none;
}

.hamburger .bar {
  width: 100%;
  height: 4px;
  background-color: #333;
  border-radius: 2px;
  transition: all 0.3s ease;
}

/* Transformations when sidebar is open */
.hamburger.open .bar:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger.open .bar:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Sidebar container styling */
.sidebar {
  position: fixed;
  left: -250px; /* Start completely off-screen */
  top: 0;
  height: 100%;
  width: 250px; /* Full width when expanded */
  background-color: #cfd1d3;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: left 0.3s ease; /* Slide in effect */
  overflow-y: auto;
  z-index: 1000;
  border-right: 1px solid #ccc;
  padding-top: 60px; /* Space for the hamburger */
}

/* Expand the sidebar when open */
.sidebar.open {
  left: 0; /* Move the sidebar into view */
}

/* Open sidebar on hover */
.hamburger:hover + .sidebar,
.sidebar:hover {
  left: 0; /* Keep sidebar open on hover */
}

/* Default button styling */
.sidebar-button {
  display: block;
  width: 80%;
  padding: 15px;
  margin: 10px auto;
  background-color: #fff; /* Default color */
  border: none;
  color: black; /* Default text color */
  font-size: 16px;
  text-align: center;
  border-radius: 8px;
  text-decoration: none;
  transition: background-color 0.2s ease, color 0.2s ease;
}

/* Button hover styling */
.sidebar-button:hover {
  background-color: #e0e0e0;
}

/* Active button styling - only applied to the selected option */
.sidebar-button.active {
  background-color: #007bff;
  color: white;
}

/* Overlay Styling */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 900; /* Below the hamburger and sidebar */
}

/* Optional: Media Queries for Responsiveness */
@media (max-width: 768px) {
  .sidebar {
    width: 200px;
    left: -200px;
  }

  .sidebar.open {
    left: 0;
  }

  .hamburger {
    width: 25px;
    height: 20px;
  }

  .sidebar-button {
    font-size: 14px;
    padding: 12px;
  }
}
