.crime-leaderboard {
    width: 100%;
    margin: 20px auto;
    font-family: Arial, sans-serif;
  }
  
  .leaderboard-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  .leaderboard-title-h3{
    text-align: center;
    font-size: 18px;
    color: #333;
  }
  
  .leaderboard-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .leaderboard-header {
    background-color: #008c1a;
    color: white;
  }
  
  .leaderboard-column {
    padding: 8px;
    text-align: left;
    border-bottom: 2px solid #ddd;
  }
  
  .leaderboard-right {
    text-align: right;
  }
  
  .leaderboard-row {
    font-weight: normal;
    color: rgb(0, 0, 0);
    cursor: pointer;
  }
  
  .leaderboard-highlight {
    font-weight: bold;
    color: rgb(255, 69, 0);
  }
  
  .leaderboard-cell {
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }
  
  .loading-text {
    text-align: center;
    font-size: 18px;
    color: #555;
  }
  