/* searchCrime.css */

/* Style for the input fields */
.crime-add-filter-container{
    display: flex;
    justify-content:center;
    margin-top: 120px;  
    width: 100vw;
    padding: 10px;
}
.searchCrime-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-left:40px;
    flex: 0.6;
    padding: 20px 10px;
    align-items: center;
    opacity: 0.9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.searchCrime-container-inputs{
    display: flex;
    margin: 10px 0;
    justify-content: space-between;
    width: 100%;
}
.searchCrime-container-inputs>*{
    margin:10px;
}

.searchCrime-container-inputs input[type="date"],
.searchCrime-container-inputs select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
 
.searchCrime-container-inputs button {
    width: 100%;
    padding: 10px;
    background-color: #009ADC;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.searchCrime-container table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;    
}

.searchCrime-container th {
    background-color: #ddd;
    color: #333;
    padding: 8px;
    text-align: left;
}

.searchCrime-container td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

.searchCrime-container tr:nth-child(even) {
    background-color: #f2f2f2;
}

@media screen and (max-width: 1000px) {
    .searchCrime-container input,
    .searchCrime-container button,
    .searchCrime-container th,
    .searchCrime-container td {
        padding: 4px;
    }
    
    .searchCrime-container th,
    .searchCrime-container td {
        font-size: 12px;
    }
    .crime-add-filter-container{
        flex-direction: column;
        margin-top: 120px;
        width: 95vw;
        width: 100%;
    }    
    .searchCrime-container {
        display: flex;
        flex-direction: column;
        margin-left:0px;
        flex: 0.6;
        padding: 20px 10px;
        margin-top: 40px;
    }
}
@media screen and (max-width: 440px) {
    .crime-add-filter-container{
        width: 95vw;
        margin-left: 390px;
        zoom: 0.95;
    }    
}