.common-container {
  font-family: Arial, sans-serif;
  background-color: #fff;
  color: #000;
  padding: 20px;
  border-radius: 8px;
  max-width: 900px;
  margin: auto;
}
.table-table{
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  font-size: 14px;
}
/* .table1 {
  padding-left: 0;
  width: 100%;
  table-layout: fixed;
} */
.styled-textarea {
  width: 100%;
  height: auto;
  resize: none; 
  border: none; 
  background: transparent;
  outline: none; /* Remove focus outline */
  font-size: inherit; /* Inherit font size from parent */
  padding: 0;
  font-family: inherit; 
  white-space: pre-wrap; 
  line-height: 1.5;
}
.table-td {
  /* border: 1px solid #000000;  */
  /* padding: 8px;
  text-align: left; 
  vertical-align: top;
  text-align: left;
  padding-left: 1 px;
  color: #000000;
  font-family: Arial, sans-serif;
  font-size: 10pt;
  background-color: white; */
  color: #000;
  border: 1px solid #000;
  padding: 8px 12px;
  text-align: left;
  font-size: 14px;
}
.table-th {
  /* border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000; */
  /* vertical-align: bottom;
  color: #000000;
  font-family: Arial, sans-serif;
  font-size: 10pt;
  background-color: white; */
  background-color: #ffffff;
  text-transform: uppercase;
  border: 1px solid #000;
  padding: 8px 12px;
  text-align: left;
  font-size: 14px;
}
.firstColT1 {
  width: 6%;
}
.table-select {
  width: 100%;
  height: auto;
  resize: none; /* Prevent resizing */
  border: none; 
  background: transparent; /* Make background transparent */
  outline: none; /* Remove focus outline */
  font-size: inherit; /* Inherit font size from parent */
  padding: 0; /* Remove padding */
  font-family: inherit; /* Inherit font family */
  white-space: pre-wrap; /* Allow text wrapping and preserve line breaks */
  line-height: 1.5;
}

.table-input {
  width: 100%; /* Ensures inputs fill the width of their parent td */
  padding: 4px;
  box-sizing: border-box; /* Prevents inputs from overflowing */
}
