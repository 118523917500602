.dashboard{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    /* flex-wrap: wrap; */
    width: 100%;
    box-sizing: border-box;    
    backdrop-filter: blur(2px);
}
.dashboard-left {
    display: flex;
    flex-direction: column;
    width: 35%;
    padding: 10px;
    justify-content: center;
    margin-right: 20px;
    margin-top: 80px;
}

.dashboard-left>div {
    background-color: white;
    border:1px solid rgba(75, 192, 192, 0.5);
    padding: 3px 5px 3px 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.dashboard-left-userinfo {
    margin-bottom: 20px;
    
}

.dashboard-left-filter {
    margin-bottom: 20px;
    cursor: pointer;
}

.dashboard-left-filter select {
        margin-right: 10px;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 14px;
        background-color: white;
        color: #333;
        min-width: 100px;
}
.dashboard-left-filter>button{
    margin-right: 10px;
    padding: 8px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    background-color: skyblue;
    color: white;
    min-width: 100px;
    cursor: pointer;
    background: #009ADC;

}

.dashboard-left-filter>button:hover {
    background-color: white;
    color: #009ADC;
    border: 1px solid ;
}
.dash-board-dutychart{
    margin-bottom: 20px;
    cursor: pointer;
}
.dashboard-left-crime-upload {
    cursor: pointer;
}
.dashboard-left h3,
.dashboard-left h4 {
    font-weight: normal;  /* Set font weight to normal */
}


.dashboard-right {
    box-sizing: border-box;
    width: 100%;
    margin-top: 5%;

}
@media (max-width: 1024px) {
    
    .dashboard-right>div {
        background-color: white;
        border:1px solid rgba(75, 192, 192, 0.5);
        padding: 5px;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    
    }
}


.crimechart>canvas{
    width: 64vw;
}
.crimechart-right{
    display:flex ;
}
.crimechart-right>label{
    display: flex;
    padding: 10px;
    flex-direction: column;
}
.crimechart-right>*>select{
    padding:10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    color: #333;
    min-width: 140px;
    outline: none;
    max-width: 120px;
}

.dashboard-lineChart{
background-color: white;
display: flex;
width:fit-content;
margin-left: auto;
margin-right: auto;
padding: 10px;
margin-top: 20px;
margin-bottom: 20px;
border:1px solid rgba(75, 192, 192, 0.5);
padding: 5px;
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}
@media screen and (min-width: 300px) and (max-width: 440px)  {
    .dashboard-lineChart {
        justify-content: center;

    }
    .dashboard-right>div{
        padding: 10px;
        position: relative;
        left: 148px;
        width: 68%;
    }
    }

.chart-main{
    display: flex;
    width: 100%;
    width: 95vw;
    justify-content: center;
    box-sizing: border-box;
}
.crimechart-line{
    display:flex;
    flex-direction: row-reverse;
    box-sizing: border-box;
    justify-content: center;
}
.crimechart-line>canvas{
    width: 50vw !important;
}
.chart-polar>canvas{
    width: 34vw !important ;
}
.crimechart-line-right>label{
    display: flex;
    padding: 10px;
    flex-direction: column;

}
.crimechart-right-line>label>select{
    padding:10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    color: #333;
    min-width: 140px;
    outline: none;
    max-width: 120px;
}   









@media (max-width: 1000px) {
    .dashboard{
        flex-wrap: wrap;
        justify-content: center;
    }
    .dashboard-left {
        width: 100%;
        padding: 10px;
        margin-right: 4px;
        margin-top: 80px;
    } 
    .dashboard-right>div {
        padding: 10px;
    }
    .crimechart{
        flex-wrap:wrap;
    }
    .chart-main{
        display: flex;
        width: 98%;
        flex-direction: column;
    }
    .crimechart-line{
        display:flex;
        flex-direction: column-reverse;
        justify-content: center;
        width: 91vw;
    }
    .crimechart-line>canvas{
        width: 95%!important;
        margin-bottom: 100px;
        margin-top: 20px;
    }
    .chart-polar>canvas{
        width: 95% !important ;
    }
}   
@media (max-width: 440px) {
    .dashboard{
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        margin-left: 200px !important;
    }
    .dashboard-left {
        width: 100%;
        padding: 10px;
        margin-right: 4px;
        margin-top: 80px;
        max-width: 90vw;
        position: relative;
        left: 60px;
    } 
    
    .crimechart{
        flex-wrap:wrap;
        max-width: 90vw;
    }
    .dashboard-lineChart {
        justify-content: center;
        position: relative;
        left: 255px;
    }
}   