.terms-conditions-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
}

.terms-conditions-container h1 {
    text-align: center;
    margin-bottom: 20px;
}

.terms-conditions-container h2 {
    margin-top: 20px;
    color: #2c3e50;
}

.terms-conditions-container p {
    margin-bottom: 10px;
}
