.duty-roster {
  font-family: Arial, sans-serif;
  background-color: #fff;
  color: #000;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 900px;
  margin: auto;
}

.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  line-height: 1;
}

.header .top-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.header-row p {
  margin: 0;
  padding: 0;
}

.header-row .left {
  text-align: left;
}

.header-row .right {
  text-align: right;
}

.header .left {
  text-align: left;
}

.header .right {
  text-align: right;
}

.roster-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: -150px;
}

.roster-table th,
.roster-table td {
  border: 1px solid #000;
  padding: 4px 6px;
  text-align: left;
  word-wrap: break-word;
  white-space: nowrap;
}

.roster-table th {
  background-color: #ffffff;
}

.roster-table td {
  color: #000;
}

.roster-table tr:nth-child(even) {
  background-color: #ffffff;
}

.roster-table tr:nth-child(odd) {
  background-color: #ffffff;
}

.roster-table td:first-child {
  font-weight: bold;
}

.common-container {
  font-family: Arial, sans-serif;
  background-color: #fff;
  color: #000;
  padding: 20px;
  border-radius: 8px;
  max-width: 900px;
  margin: auto;
}

.header,
.administrative-duty h2 {
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.header-row p {
  margin: 0;
  padding: 0;
}

.header-row .left {
  text-align: left;
}

.header-row .right {
  text-align: right;
}

.table-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  font-size: 14px;
}

.table-th {
  background-color: #ffffff;
  text-transform: uppercase;
  border: 1px solid #000;
  padding: 8px 12px;
  text-align: left;
  font-size: 14px;
}

.table-td {
  color: #000;
  border: 1px solid #000;
  padding: 8px 12px;
  text-align: left;
  font-size: 14px;
}

.table-h2 {
  text-align: center;
  margin-bottom: 15px;
  font-size: 20px;
  text-transform: uppercase;
}
.table-option {
  white-space: normal; /* Allows text to wrap inside the option */
}
.table-input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: rgb(0, 0, 0);
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}
/* input[type="text"]:focus {
  outline: none;
} */
.administrative-duty {
  font-family: Arial, sans-serif;
  color: #000;
  padding: 20px;
  border-radius: 8px;
  margin: auto;
  width: 100%;
}

.duty-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  margin-bottom: 20px;
}
.duty-table tr:nth-child(even) {
  background-color: #ffffff;
}

.duty-table tr:nth-child(odd) {
  background-color: #ffffff;
}

.duty-table td:first-child {
  text-align: center;
  font-weight: bold;
}

.duty-container {
  font-family: Arial, sans-serif;
  background-color: #fff;
  color: #000;
  padding: 20px;
  border-radius: 8px;
  max-width: 900px;
  margin: auto;
}
.duty-table th {
  background-color: #ffffff;
  color: #000;
  text-transform: uppercase;
  padding: 8px 12px;
  text-align: center;
  border: 1px solid #000000;
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
}
.duty-table td select {
  width: 100%;
  box-sizing: border-box;
}
.picketSelect {
  border: 1px solid #979292;
}

.duty-table .thin-column1 {
  width: 11%;
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
}
.duty-table .thin-column2 {
  width: 45px;
}
.duty-table .thin-column3 {
  width: 65px;
}
.duty-table .thick-column1 {
  width: 10%;
}
.duty-table .thick-column2 {
  width: 25%;
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
}
.duty-table .thick-column {
  width: 25%;
}

.duty-table .staff-name {
  height: 80px;
  width: 100%;
  box-sizing: border-box;
}

.duty-table td {
  border: 1px solid #000000;
  padding: 8px 12px;
  vertical-align: top;
  color: #000;
}

.duty-container {
  margin: 30px;
  border: 2px solid white;
  width: 100%;
}

.duty-input1 {
  width: 100%;
  height: 115px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  resize: none;
  padding: 5px;
  font-family: Arial, sans-serif;
  font-size: 14px;
}
.duty-input2 {
  width: 100%;
  height: 130px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  resize: none;
  padding: 5px;
  font-family: Arial, sans-serif;
  font-size: 14px;
}
.duty-input3 {
  width: 100%;
  height: 240px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  resize: none;
  padding: 5px;
  font-family: Arial, sans-serif;
  font-size: 14px;
}
.duty-table input[type="text"] {
  width: 100%; /* Allow input fields to expand and fill the cell */
  word-wrap: break-word;
  word-break: break-word;
}

.signatures {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

.signatures p {
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
}

.actions {
  margin-bottom: 20px;
  text-align: center;
}

.actions button {
  margin: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: black;
  border: none;
  border-radius: 5px;
}

.actions button:hover {
  background-color: #0056b3;
}

.styled-textarea {
  width: 100%;
  height: auto;
  resize: none; /* Prevent resizing */
  border: none; /* Remove border */
  background: transparent; /* Make background transparent */
  outline: none; /* Remove focus outline */
  font-size: inherit; /* Inherit font size from parent */
  padding: 0; /* Remove padding */
  font-family: inherit; /* Inherit font family */
  white-space: pre-wrap; /* Allow text wrapping and preserve line breaks */
  line-height: 1.5; /* Adjust line height for readability */
}

@media print {
  input[type="text"] {
    border: none;
    background: none;
    color: black;
    outline: none;
  }

  .roster-table input[type="text"] {
    visibility: hidden;
  }
}
