/* @tailwind base;
@tailwind components;
@tailwind utilities; */

html {
  font-family: "Montserrat", sans-serif;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  margin-top: 8em;
}

.heading {
  font-family: "Montserrat", sans-serif;
  font-size: 2em;
  text-wrap: nowrap;
  margin: 0 10px;
  font-weight: bold;
  margin-top: 2rem;
}
.subheading {
  font-family: "Montserrat", sans-serif;
  font-size: 1.3em;
  text-wrap: wrap;
  margin: 0 10px;
  color: rgba(122, 122, 122, 1);
  text-align: center;

  /* margin-top: 4em; */
}
.info {
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  font-ewight: 500;
  text-wrap: wrap;
  color: #000;
  text-align: center;
  width: 75%;
  /* font-weight: bold; */

  /* margin-top: 4em; */
  background-color: rgb(250, 250, 250);
  padding: 20px;
  border-radius: 10px;
  border:2px solid rgba(75, 192, 192, 0.5);

}
.wrapper-bottom {
  font-family: "Montserrat", sans-serif;
  font-size: 1.3rem;
  text-wrap: wrap;
  color: #000;
  text-align: center;
}

.images-wrapper1 {
  /* margin-top: 3em; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4em;
  padding: 0 4rem 0 4rem;
}
.images-wrapper2 {
  display: none;
}

.Logo {
  width: 18%;
  height: 18%;
  margin: 0 10px;
}
.policeLogo {
  scale: 0.7;
}

.carousel {
  scale: 0.75;
  margin-top: -100px;
  margin-bottom: -100px;
}
.app-carousel {
  /* scale: 0.5; */
  margin-top: -100px;
  margin-bottom: -100px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #009adc80;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}
.carousel-control-prev-icon::before,
.carousel-control-next-icon::before {
  color: #000; /* Change to your desired arrow color */
}

.team-member {
  width: 250px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.2em;
  padding: 1em 2em;
  border-radius: 1rem;
  background-color: #f1f3f5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      border:2px solid rgba(75, 192, 192, 0.5);
}
.feature {
  width: 500px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.2em;
  padding: 1em 2em;
  border-radius: 1rem;
  background-color: #f1f3f5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      border:2px solid rgba(75, 192, 192, 0.5);
}

.feature-list {
  text-align: left;
}

.team-member-image {
  width: 100px;
  height: 100px;
  background-color: #f1f3f580;
  border-radius: 50%;
  border: 2px solid #d9d9d970;
}

.team-member-name {
  font-family: "Montserrat", sans-serif;
  font-size: 1.25em;
  /* text-wrap: nowrap; */
  /* margin: 0 10px; */
  font-weight: bold;
  margin-top: 1rem;
  text-align: center;
}

.team-member-role {
  font-family: "Montserrat", sans-serif;
  font-size: 0.9rem;
  /* margin: 0 10px; */
  color: #444444;
  margin-top: 0.5rem;
  text-align: center;
  line-height: 1.5;
  font-weight: 300;
}
.feature-name {
  font-family: "Montserrat", sans-serif;
  font-size: 1.25em;
  /* text-wrap: nowrap; */
  /* margin: 0 10px; */
  font-weight: bold;
  margin-top: 1rem;
  text-align: center;
}

.feature-role {
  font-family: "Montserrat", sans-serif;
  font-size: 0.9rem;
  /* margin: 0 10px; */
  color: #444444;
  margin-top: 0.5rem;
  text-align: center;
  line-height: 1.5;
  font-weight: 300;
}

.team-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  flex-wrap: wrap;
  margin-top: 1em;
  width: 90%;
}
.features-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  flex-wrap: wrap;
  margin-top: 1em;
  width: 80%;
}
#contact {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  flex-wrap: wrap;
  margin-top: 4em;
  width: 80%;
  background-color: #f8f9fa;
  padding: 2em;
  border: 1px solid #d9d9d9;
  border-radius: 1rem;
}
/* .contact-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 1em;
    width: 80%;
} */
.contact-role {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 600;
  /* text-wrap: nowrap; */
  /* margin: 0 10px; */
  text-align: center;
}
.contact-name {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 600;
  /* text-wrap: nowrap; */
  /* margin: 0 10px; */
  text-align: center;
}
.contact-info {
  font-family: "Montserrat", sans-serif;
  /* font-size: 1.0rem;
    line-height: 1.5;
    font-weight: 600; */
  /* text-wrap: nowrap; */
  /* margin: 0 10px; */
  text-align: center;
}
.contact-title {
    margin-top: 0;
}

/* responsive */
/* @media (max-width: 768px) {
    .team-wrapper {
        flex-direction: column;
        gap: 2em;
        }
        .team-member {
            width: 100%;
            }
            .team-member-image {
                width: 70px;
                height: 70px;
                }
                .team-member-name {
                    font-size: 1em;
                    }
                    .team-member-role {
                        font-size: 0.8rem;
                        }
                        } */

/* responsive */
@media (max-width: 500px) {
  .main-wrapper {
    width: 100vw;
    margin-left: 98vw;
    overflow-x: hidden;
  }
  .wrapper {
    /* width: 100vw; */
  }
  .heading {
    font-size: 2em;
    text-wrap: wrap;
    text-align: center;
    margin-top: 2rem;
  }
  .policeLogo {
    scale: 0.6;
  }
  .Logo {
    width: 55%;
  }
  /* .team-member {
        padding: 0;
        scale: 0.6;
        }
        .team-member-name {
            font-size: 1.2em;
            }
            .team-member-role {
                font-size: 1rem;
                } */
  .carousel {
    scale: 0.2;
  }
  .views {
    scale: 0.8;
    text-wrap: wrap;
    text-align: center;
    margin: auto;
  }
  .subheading {
    margin-top: -1rem;
  }
  /* .team-wrapper {
                    gap: 0;
                    width: 160%;
                    justofy-content: center;
                    } */

  .team-member {
    width: 150px;
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.2em;
    padding: 0em 2em;
    border-radius: 1rem;
    background-color: #f1f3f5;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border:2px solid rgba(75, 192, 192, 0.5);
    /* width: fit-content; */
    /* white-space: wrap; */
    /* margin: auto; */
  }
  .team-member-image {
    width: 70px;
    height: 70px;
    background-color: #f1f3f580;
    border-radius: 50%;
    border: 2px solid #d9d9d970;
  }
  .team-member-name {
    text-wrap: wrap;
    font-size: 0.80rem;
    margin-top: 1rem;
  }
  .team-member-role {
    text-wrap: wrap;;
    font-size: 0.55rem;
    margin-top: 0.5rem;
  }
  .team-wrapper {
    /* width: 100vw; */
    display: flex;
    flex-wrap: nowrap;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }
  #development-team {
    width: 100vw;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;
    justify-content: flex-start;
    margin-left: 3rem;
    padding-right: 3rem;
    gap: 1rem;
  }

  .heading {
    font-size: 1.5em;
    text-wrap: wrap;
    margin: 0 10px;
    font-weight: bold;
    margin-top: 2rem;
  }
  .carousel {
    scale: 0.7;
    margin-top: 0px;
  }

  .feature-name {
    font-size: 0.8rem;
    margin-top: 0rem;
  }
  .feature-role {
    font-size: 0.5rem;
    /* margin-top: 0.5rem; */
    display: none;
  }
  .feature {
    width: 130px;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 0em 2em; */
    border-radius: 1rem;
    background-color: #f1f3f5;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border:2px solid rgba(75, 192, 192, 0.5);
  }
  .features-wrapper {
    width: 100vw;
    gap: 1rem;
  }
  .images-wrapper1 {
    display: none;
  }
  .images-wrapper2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4em;
    /* padding: 0 4rem 0 4rem; */
  }

  .Logo {
    width: 100px;
    height: 80px;
    /* margin: 0 10px; */
    /* padding-left: -100px; */
  }

  .policeLogo {
    /* scale: 0.4; */
    width: 150px;
    height: 150px;
  }
  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 350px;
    margin-bottom: -50px;
  }
  .info {
    font-size: 0.9rem;
    width: 80%;
  }
  .contact-info {
    font-size: 0.8rem;
  }
    .contact-name {
        font-size: 0.8rem;
    }
    .contact-role {
        font-size: 0.8rem;
    }
    .contact-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 80%;
    }
    .contact-title {
        font-size: 1.5rem;
        margin-top: 0;
    }

}
