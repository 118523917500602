.privacy-policy-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
}

.privacy-policy-container h1 {
    text-align: center;
    margin-bottom: 20px;
}

.privacy-policy-container h2 {
    margin-top: 20px;
    color: #2c3e50;
}

.privacy-policy-container h3 {
    margin-top: 15px;
    color: #34495e;
}

.privacy-policy-container p {
    margin-bottom: 10px;
}

.privacy-policy-container ul {
    margin-left: 20px;
    list-style-type: disc;
}

.privacy-policy-container ul li {
    margin-bottom: 10px;
}
