.nav {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 20px; /* Adjust the gap as needed */
    font-family: 'Montserrat', sans-serif;
    font-size: small;
    justify-content: space-between;
    padding: 10px 20px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    flex-wrap: wrap;
   
}
.h1.active {
    text-decoration: underline;
    color: rgb(4, 4, 128); /* Active link text color (adjust as needed) */
    font-weight: bolder;
  }
  
.menu-toggle {
    display: none;
    cursor: pointer;
  }
  
  .menu-items {
    display: flex;
    gap: 10px;
  }
  
  .menu-items .h1 {
    font-size: 20px;
    margin: 0 10px;
    font-weight: bolder;
  }
.menu-icon{
    display:none;
}
  
.h1 {
    font-size: 20px;
    margin: 0 10px;
    font-weight: bolder;
    color: black;
    flex: 1;
}

.nav img {
    width: 80px; /* Adjust width as needed */
    height: auto; /* Maintain aspect ratio */
}
.user-icon{
    height: 5px;
}
.profile-body{
    overflow-x: hidden;
}
.menu-icon{
  width: 30px;
  height: 30px;
  cursor: pointer;
}

  
  
  
  
  
  .nav-dropdown {
    display: none;
    position: absolute;
    right:0;
    top:20vw;
    background-color: #f8f4f4;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    display: flex;
    flex-direction: column;
  }

  
  .nav-dropdown .h1 {
    color: rgb(18, 18, 18);
    padding: 12px 16px;
    text-decoration: none;
    right:0;
  
    text-align: left;
    
  }

@media screen and (max-width: 768px) {
    .nav{
    
    display: flex; /* Make sure flexbox is used */
    flex-direction: row; /* Ensure items stay in a row */
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
    justify-content: center; /* Center items horizontally */
    gap: 10px; /* Add spacing between items */
    padding: 10px; /* Adjust padding for spacing */
    position: absolute; /* Position it absolutely to the left */
    left: 0; /* Align it to the left */
    width: 100%; /* Make it span the full width */
    margin-left: 0;
    }

    .h1 {
        font-size: 10px; /* Adjust font size for smaller screens */
        margin: 5px; /* Adjust margin for spacing between items */
    }

    .nav img {
        width: 30px; /* Adjust image size for smaller screens */
        margin-bottom: 5px; 
    /* Add spacing between image and text */
    }
}
@media screen and (max-width: 1010px) {
    .h1{
        font-size: 15px;
    }
    .nav-dropdown{
      display: none;
    }
}
@media screen and (max-width:842px) {
    .h1{
        font-size: 13px;
    }
}
 @media screen and (max-width:587px) {
    .nav{
        position: absolute;
        left:0;
    }
    .h1{
        font-size: 10px;
    }
    .nav img{
        margin-left: 0;
        height:40px;
        width:40px;
    }
} 
@media screen and (max-width:506px) {
    .h1{
        visibility: hidden;
    }
    
    .nav{
      display:flex;
      width: 100%;
      flex-wrap: wrap;
    }
    .nav img{
      
        height: 70px;
        width:70px;
    }
    .user-icon{
        display: none;
    }
    .menu-icon {
        display: block;
        height: 30px;
        width: 30px;
        right: 0;
        top:0;
        margin-right:0px;
        position: absolute;
        
      }
    
      .nav-dropdown {
        display: block;
        display: flex; /* show dropdown menu on small screens when toggled */
        flex-direction: column;
      }
      .nav-dropdown .h1{
        display: block;
      }
      
    }
    @media (min-width:50px) and (max-width:506px) {
      .menu-icon{
        right: 0;
        margin-right: 0px;
      }
      .nav-dropdown{
        display: block;
      }
      .nav-dropdown .h1{
       visibility: visible;
        
      }
    }
    

@media (min-width: 503px) and (max-width: 695px){
    .user-icon{
        height: 0.1px;
        width: 0.1px;
    }
    
}

@media (min-width: 563px) and (max-width: 768px){
    .nav img{
        height:40px;
        width:50px
    }
}
@media (min-width: 320px) and (max-width: 505px){
  .nav-dropdown .h1{
    display: block;
  }
}


/* Styles for .leftbox */
.leftbox {
    width: 850px;
    max-height: calc(100vh - 200px); /* Adjust as needed */
    position: absolute;
    top: 143px;
    left: 44px;
    border-radius: 20px;
    border: 3px solid #B7E2E7;
    background-color: white;
    margin-left: 50px;
    margin-bottom : 100px;
    margin-top: 20px;
  }
  
  /* Styles for .rightbox */
  .rightbox {
    position: absolute;
    width: 250x;
    height: 263px;
    top: 250px;
    left: 1000px;
    overflow-x: hidden;
  }
  
  /* Styles for .images within .rightbox */
  .rightbox .images {
    width: 50%; /* Adjust width as needed */
    height: auto; /* Maintain aspect ratio */
  }
  
  /* General profile text styles */
  .profile {
    padding-left: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 30px;
  }
  
  /* Styles for .user image */
  .user {
    width: 108px;
    height: 108px;
    position: absolute;
    top: 10px;
    left: calc(50% - 54px); /* Center horizontally */
  }
  
  /* Styles for .profilerole */
  .profilerole {
    font-size: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
    line-height: 43px;
    padding-left: 20px;
    position: relative;
    top: 50px;
  }
  
  /* Styles for .userclass */
  .userclass {
    position: relative;
    top: 100px;
  }
  