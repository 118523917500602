/* src/components/CrimeForm.css */
.back {
    position: relative;
    left: -580px;
    top: 92px;
    background-color: white;
    width: 403px;
    height: 74px;
    border-radius: 18px;
    border: 2px solid #2c2323; /* Adjust the border color and width as needed */
    box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.1); /* Adjust the shadow properties as needed */
    cursor: pointer;
}

.blockForImageArrow{
    position: relative;
    margin-left: 45px;
    top: 23px;
}
.textHere{
    position: relative;
    margin-left: 95px;
    top: -4px;
    font-family: sans-serif;
}
.crime-form {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    background: white;
    opacity: 0.9;
    display: flex;
    flex-direction: column;
    flex: 0.3;
  }
  
  .crime-form h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .crime-form div {
    margin-bottom: 15px;
  }
  
  .crime-form label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
  }
  
  .crime-form input[type="text"],
  .crime-form select,
  .crime-form input[type="number"],
  .crime-form input[type="date"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .crime-form button {
    width: 100%;
    padding: 10px;
    background-color: #009ADC;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .crime-form button:hover {
    background-color: #45a049;
  }
  