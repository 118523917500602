.otp-verify-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 40px;
  border: 1px solid #ddd;
  border-radius: 10px;
  max-width: 400px;
  margin: 0 auto;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.otp-verify-container h2 {
  margin-bottom: 25px;
  font-size: 26px;
  color: #333;
  font-weight: 600;
}

.otp-verify-container form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.otp-verify-container input[type="text"] {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.otp-verify-container button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
}

.otp-verify-container button:hover {
  background-color: #0056b3;
}

.otp-verify-container p {
  margin-top: 10px;
  font-size: 16px;
  color: #d9534f;
}