.Map {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
  }

.map_pcr_crime {
    margin-top: 85px;
}
.map_plot{
    margin-top: -80px;
}