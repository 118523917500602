.back {
  position: relative;
  left: -580px;
  top: 92px;
  background-color: white;
  width: 403px;
  height: 74px;
  border-radius: 18px;
  border: 2px solid #2c2323;
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.blockForImageArrow {
  position: relative;
  margin-left: 45px;
  top: 23px;
}

.textHere {
  position: relative;
  margin-left: 95px;
  top: -4px;
  font-family: sans-serif;
}

.pcr-form {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  background: white;
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  flex: 0.3;
}

.pcr-form h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.pcr-form div {
  margin-bottom: 15px;
}

.pcr-form label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.pcr-form input[type="text"],
.pcr-form select,
.pcr-form input[type="number"],
.pcr-form input[type="date"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.pcr-form button {
  width: 100%;
  padding: 10px;
  background-color: #009ADC;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.pcr-form button:hover {
  background-color: #45a049;
}

.pcr-add-filter-container {
  display: flex;
  justify-content: center;
  margin-top: 120px;
  width: 100vw;
  padding: 10px;
}

@media screen and (max-width: 1000px) {
  .pcr-add-filter-container {
      flex-direction: column;
      margin-top: 120px;
      width: 95vw;
      width: 100%;
  }
}

@media screen and (max-width: 440px) {
  .pcr-add-filter-container {
      width: 95vw;
      margin-left: 390px;
      zoom: 0.95;
  }
}
