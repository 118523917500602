/* Container for the PCR search form and table */
.pcr-add-filter-container {
    display: flex;
    justify-content: center;
    margin-top: 120px;
    width: 100vw;
    padding: 10px;
}

.searchPCR-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-left: 40px;
    flex: 0.6;
    padding: 20px 10px;
    align-items: center;
    opacity: 0.9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.searchPCR-container-inputs {
    display: flex;
    margin: 10px 0;
    justify-content: space-between;
    width: 100%;
}

.searchPCR-container-inputs > * {
    margin: 10px;
}

/* Styles for the dropdown and date input */
.searchPCR-container-inputs input[type="date"],
.searchPCR-container-inputs select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

/* Button styling */
.searchPCR-container-inputs button {
    width: 100%;
    padding: 10px;
    background-color: #009ADC;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

/* Table styling */
.searchPCR-container table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.searchPCR-container th {
    background-color: #ddd;
    color: #333;
    padding: 8px;
    text-align: left;
}

.searchPCR-container td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

.searchPCR-container tr:nth-child(even) {
    background-color: #f2f2f2;
}

/* Media queries for responsive design */
@media screen and (max-width: 1000px) {
    .pcr-add-filter-container {
        flex-direction: column;
        margin-top: 120px;
        width: 95vw;
        width: 100%;
    }
    .searchPCR-container {
        display: flex;
        flex-direction: column;
        margin-left: 0px;
        flex: 0.6;
        padding: 20px 10px;
        margin-top: 40px;
    }
}

@media screen and (max-width: 440px) {
    .pcr-add-filter-container {
        width: 95vw;
        margin-left: 390px;
        zoom: 0.95;
    }
}
