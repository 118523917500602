.nav {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px; /* Adjust the gap as needed */
  font-family: "Montserrat", sans-serif;
  font-size: small;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255);
}
a.h1,
a.h1.active {
  text-decoration: none;
}
.nav1 {
  display: flex;
  align-items: center;
  gap: 20px; /* Adjust the gap as needed */
  font-family: "Montserrat", sans-serif;
  font-size: small;
  z-index: 1000;
}
.user-icon-container {
  display: flex;
  box-sizing: border-box;
  max-width: 60px !important;
  max-height: 60px !important;
  overflow: hidden;
  border-radius: 50%;
  align-self: center;
}
.user-icon {
  cursor: pointer;

  width: 100%;
  height: auto;
}

.dropdown-menu {
  position: absolute;
  top: 50px;
  right: 10px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
}

.dropdown-menu button {
  background-color: #f8f9fa;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.dropdown-menu button:hover {
  background-color: #e9ecef;
}

/* .h1.selected::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  height: 5px;
  background-image: url('../../assets/line.png');
  background-size: cover;
  background-repeat: no-repeat;
} */

.h1 {
  margin: 0 15px;
  position: relative;
  cursor: pointer;
}
/*   
.h1.selected::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  height: 5px;
  background-image: url('../../assets/line.png');
  background-size: contain;
  background-repeat: no-repeat;
}
 */

.h1 {
  font-size: 1.5em;
  text-wrap: nowrap;
  margin: 0 10px;
  font-weight: bold;
}

.menu-icon {
  /* display: none;
  cursor: pointer; */
}

.login {
  font-family: "Montserrat", sans-serif;
  border: 1px solid #fff;
  padding: 10px 25px 10px 25px;
  font-size: 20px;
  margin: 0 10px;
  font-weight: bold;
  border-radius: 12px;
  color: #fff;
  text-decoration: none;
  background-color: #08529e;
}
.text {
  font-family: "Montserrat", sans-serif;
  padding: 10px;
  font-size: 20px;
  margin: 0 10px;
  font-weight: bold;
  color: #000;
  text-decoration: none;
}
.text:visited,
.text:active,
.text:focus {
  text-decoration: none;
}
@media screen and (max-width: 768px) {
  .nav {
    flex-direction: row;

    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 10px;
    max-height: 80px;
  }
  .nav1 {
    display: none;
  }
  .login {
    scale: 0.7;
    margin: 0 30px 20px 30px;
  }
  .main-logo {
    scale: 0.7;
  }
  .nav-dropdown {
    font-size: 8px;
  }
  .menu-icon {
    scale: 0.7;
  }
}

.leftbox {
  width: 850px;
  height: 620px;
  top: 143px;
  left: 44px;
  border-radius: 20px;
  border: 3px;
  border: solid;
  background: white;
  border-color: #b7e2e7;
  margin-left: 50px;
}
.rightbox {
  position: absolute;
  width: 360px;
  height: 263px;
  top: 250px;
  left: 1000px;
}

.profile {
  position: absolute;
  padding-left: 20px;

  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.user {
  width: 108px;
  height: 108px;
  position: relative;
  left: 380px;
  top: 100px;
}
.profilerole {
  font-size: 30px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: bolder;
  line-height: 43px;
  position: relative;
  top: 135px;
  padding-left: 20px;
}
/* .h1.selected::after {
content: '';
position: absolute;
bottom: -5px;
left: 0;
right: 0;
height: 5px;
background-image: url('../../assets/line.png');
background-size: cover;
background-repeat: no-repeat;
} */
