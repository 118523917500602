/* css for staffmembers */
.fetched-user {
  background-color: white;
  /* width: 100%; */
  /* margin-right: -400px; */
  /* margin-top: 20px; */
  margin-top: 30px;
  padding: 20px;
  /* padding: 20px; */

  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.fetched-user table {
  width: 100%;
  border-collapse: collapse;
}

.fetched-user th,
.fetched-user td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.fetched-user th {
  background-color: #f2f2f2;
  font-weight: bold;
  color: #333;
}
.fetched-user td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.fetched-user th {
  background-color: #f2f2f2;
}

.fetched-user tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.fetched-user tbody tr:hover {
  background-color: #ddd;
}

@media screen and (max-width: 510px) {
  .fetched-user {
    margin-left: auto;
    margin-right: auto;

    width: 70%;
  }
}

@media (min-width: 500px) {
  .fetched-user {
    margin-left: auto;
    margin-right: auto;

    width: 75%;
  }
}

@media (min-width: 600px) {
  .fetched-user {
    margin-left: auto;
    margin-right: auto;

    width: 65%;
  }
}

@media (min-width: 768px) {
  .fetched-user {
    margin-left: auto;
    margin-right: auto;

    width: 70%;
  }
}

@media (min-width: 992px) {
  .fetched-user {
    margin-left: auto;
    margin-right: auto;

    width: 80%;
  }
}

@media (min-width: 1190px) {
  .fetched-user {
    width: 90%;
  }
}
.select_copstation {
  left: 0px;
}
.userlist {
  border: 3px solid #b7e2e7;
}
.staffmembers {
  text-align: center; /* Centers text horizontally */
  margin-top: 5px;
}
